import { Component, OnInit, Input, OnChanges, ViewEncapsulation, ViewChild, EventEmitter, Output, ElementRef, HostListener, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { IndexedDBService } from '../../../services/indexed-db.service';
import { RoomService } from '../../../services/room.service';
import { Constants, JsonFile } from '../../../models/constants';
import { UtilService } from '../../../services/util.service';
import { Subscription } from 'rxjs';
import { CurrentRoom, FavoriteRoom } from '../../../models/room';
import { DeviceService } from '../../../services/device.service';
import { PanelManagerService } from '../../../services/panel-manager.service';
import { MessagePanelService } from '../../../services/messagePanel.service';
import {
  faHeadset, faArrowLeft, faHeart, faBars, faRedoAlt, faTimes, faUserFriends, faComment, faEllipsisV,
  faMicrophone, faMicrophoneSlash, faHandPaper, faCamera, faFilm, faIcons, faVolumeOff, faVolumeUp, faPaperPlane, faUserTie, faVolumeDown, faRecordVinyl, faShareAltSquare, faPhone,
  faFolder,
  faCommentAlt,
  faTextWidth,
  faUserNinja,
  faTrophy
} from '@fortawesome/free-solid-svg-icons';
import { EditorContextMenuComponent } from './context-menu/context-menu.component';
import { RoomUser, RoomUserEnum } from '../../../models/room-user';
import { ContextMenuService } from 'ngx-contextmenu';
import { ReportService } from '../../../services/report.service';
import { EditorOn, MainPanels, MessageSendingType, MessageType, MicrophoneState, SikayetTipleri, WhoEnterRoomTypes } from '../../../models/enums';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { EditorComponent } from '../../editor/editor.component';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { UserOnMicDirective } from './user-on-mic/user-on-mic.directive';
import { UserOnMicComponent } from './user-on-mic/user-on-mic.component';
import { ChatService } from '../../../services/chat.service';
import { ChatMessage, FileMessageData } from '../../../models/chat-message';
import { FriendListService } from '../../../services/friendlist.service';
import { StateIdList } from '../../../models/state';
import { LivekitService } from '../../../livekit/services/livekit.service';
import { EnvironmentVariableService } from 'src/app/core/services/environment-variable.service';
import { RoomUserListService } from 'src/app/core/services/room-user-list-service';

@Component({
  selector: 'app-room-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class RoomChatComponent implements OnInit, OnChanges,OnDestroy {

  faArrowLeft = faArrowLeft;
  faHeart = faHeart;
  faBars = faBars;
  faRedoAlt = faRedoAlt;
  faTimes = faTimes;
  faHeadset = faHeadset;
  faUserFriends = faUserFriends;
  faComment = faComment;
  faCommentAlt = faUserNinja;

  faMicrophone = faMicrophone;
  faMicrophoneSlash = faMicrophoneSlash;
  faHandPaper = faHandPaper;
  faCamera = faCamera;
  faFilm = faFilm;
  faIcons = faIcons;
  faYoutube = faYoutube;
  faVolumeOff = faVolumeDown;
  faVolumeUp = faVolumeUp;
  faPaperPlane = faPaperPlane;
  faUser = faUser;
  faUserTie = faUserTie;
  faEllipsisV = faEllipsisV;
  faRecord = faRecordVinyl
  faShare = faShareAltSquare
  faPhone = faPhone
  faFolder = faFolder
  faTrophy = faTrophy
  
  messageSendingType = MessageSendingType;
  mainPanels = MainPanels;
  isClickRight: boolean = false;
  isFavoriteRoom: boolean = false;
  microphoneState: MicrophoneState = MicrophoneState.Stopped;
  _microphoneState = MicrophoneState;
  isThereOnMic: boolean = false;
  p2pNotification: boolean = false;

  micUsersCount: number = 0;

  microphoneStateChangedSubscription: Subscription;
  roomUserListChangedSubscription: Subscription;
  newMessageNotificationTakenSubscription: Subscription;
  roomMicUserAppendSubscription: Subscription;


  animationToggle: boolean = false;
  showMicSettings: boolean = false;

  @ViewChild('animation', { static: false, read: ElementRef }) animation: ElementRef;
  @ViewChild('messageSendingTypeMenu', { static: false, read: ElementRef }) messageSendingTypeMenu: ElementRef;
  @ViewChild('micIcon', { static: false, read: ElementRef }) micIcon: ElementRef;

  @ViewChild(UserOnMicDirective, { static: false, read: UserOnMicDirective }) userOnMicHost: UserOnMicDirective;




  isTyping: boolean = false;
  editorMessageForSet: Promise<string> = Promise.resolve("");
  @Output() editorTextChanged = new EventEmitter<string>();
  @ViewChild('editor') editor: EditorComponent;
  emojiPanelIsOpen: boolean = false;
  lastSendMessageTime = this.utilService.newDate();
  floodmessageCount = 0;
  sendButtonEnable = true;

  editorOn = EditorOn;
  whoEnterRoomTypes = WhoEnterRoomTypes;



  display: boolean = true;
  roomUserListVisible: boolean = false;
  @Input() roomInfo: CurrentRoom;
  roomBack: string = "";
  constants = Constants;
  roomInfoUpdatedSubscription: Subscription;
  friendshipRequestNotificationSubscription: Subscription;
  userListHeight: string = 'calc(100% - 130px)';

  showRoomEditorContextMenu: boolean = false;
  @ViewChild(EditorContextMenuComponent) roomEditorContextMenuClick: EditorContextMenuComponent;

  roomUser: RoomUser;

  fileMessageReceivedSubscription: Subscription;

  constructor(
    public roomService: RoomService,
    public utilService: UtilService,
    public deviceService: DeviceService,
    public panelManagerService: PanelManagerService,
    public indexedDBService: IndexedDBService,
    private messagePanelService: MessagePanelService,
    private contextMenuService: ContextMenuService,
    private reportService: ReportService,
    public confirmationService: ConfirmationService,
    public translateService: TranslateService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private chatService: ChatService,
    private friendListService: FriendListService,
    private livekitService:LivekitService,
    public environmentVariableService:EnvironmentVariableService,
    public roomUserListService:RoomUserListService
  ) {

    this.roomInfoUpdatedSubscription = this.roomService.roomInfoUpdated.subscribe((currentRoom: CurrentRoom) => {
      console.log("chat.component roomInfoUpdatedSubscription worked - ", currentRoom);
      this.roomInfo = currentRoom;
      this.setRoomBackground();
    });

    this.microphoneStateChangedSubscription = this.roomService.microphoneStateChanged.subscribe((microphoneState: MicrophoneState) => {
      this.microphoneState = microphoneState;
    });

    this.roomUserListChangedSubscription = this.roomService.micUserListLoaded.subscribe(() => {
      this.refreshRoomMicUserViewList();
    });

    this.roomMicUserAppendSubscription = this.roomService.micUserAdded.subscribe((roomUser:RoomUser) => {
      this.appendToMicUser(roomUser);
    })

    this.newMessageNotificationTakenSubscription = this.chatService.newMessageNotificationTaken.subscribe((chatMessage: ChatMessage) => {
      if (!this.indexedDBService.isMyFriend(chatMessage.SenderId)) {
        return
      }
      if (!this.panelManagerService.showedFriendList) {
        this.p2pNotification = true;
      } else {
        this.p2pNotification = false;
      }

      if (this.panelManagerService.showedP2p) {
        this.p2pNotification = false;
      } 
    });
    
    this.fileMessageReceivedSubscription = this.roomService.roomFileMessageReceived.subscribe((data:FileMessageData) => {
      this.sendFileMessageOnRoom(data)
    })
	
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.setRoomBackground();
    this.checkFavoriteRoom();
    this.setRoomUser();
  }

  refreshPage(){
    if (this.indexedDBService.userFullInfo.RutbeFullInfo.ADI == "AIUser") {
      return
    }
    this.utilService.refreshPage()
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {

    if (!this.animation.nativeElement.contains(event.target)) {
      this.animationToggle = false;
    }

    if (!this.micIcon.nativeElement.contains(event.target)) {
      this.panelManagerService.showMicSettings = false;
    }

    if (this.messageSendingTypeMenu) {
      if (!this.messageSendingTypeMenu.nativeElement.contains(event.target)) {
        this.isClickRight = false;
      }
    }

    event.preventDefault();
    event.stopPropagation();
  }

  refreshRoomMicUserViewList() {
    this.clearMicUsers();
    this.sortByMainMic();
    if (this.roomService.micUserList.length > 0) {
      this.roomService.isThereOnMic = true
      this.micUsersCount = this.roomService.micUserList.length;
      if (this.livekitService.isRoomHasAIAgent) {
        this.micUsersCount = this.micUsersCount + 1
      }
      this.roomService.rowNumberForUserOnMic =Math.floor((this.micUsersCount - 1) / 10) + 1; // 10 user on a row 
      this.roomService.micUserList[0].OnMainMic = true //main mic indicator
      this.roomService.micUserList.forEach(roomUser => {
        this.appendToMicUser(roomUser);
      });
    }
    else{
      this.roomService.rowNumberForUserOnMic = 0
    }

    if (this.livekitService.isRoomHasAIAgent) {
      if (this.roomService.micUserList.length == 0) {
        this.roomService.rowNumberForUserOnMic = 1
        this.micUsersCount = 1
        }
      this.appendToMicUser(this.livekitService.roomAIAssistant)
    }
  }
  
  sortByMainMic() {
    const mainMicUserId = this.roomService.currentMicrophoneJsonStruct.M;
    this.roomService.micUserList.sort((a, b) => {
      if (a.KullaniciId.toString() === mainMicUserId) return -1;
      if (b.KullaniciId.toString() === mainMicUserId) return 1;
      return 0;
    });
  } 
  sortMicUserList() {
    this.roomService.micUserList.sort(this.sortByAll.bind(this));
  }

  sortByAll(a: RoomUser, b: RoomUser) {
    return this.utilService.sortByBool(a, b, RoomUserEnum[RoomUserEnum.OnMainMic], () => {
      return this.utilService.sortByMultiBool(a, b, RoomUserEnum[RoomUserEnum.OnSecondMic], RoomUserEnum[RoomUserEnum.HandUp], () => {
        return this.utilService.sortByBool(a, b, RoomUserEnum[RoomUserEnum.OnSecondMic], () => {
              return this.utilService.sortByStringInNotEmptyOrGuidEmpty(a, b, RoomUserEnum[RoomUserEnum.CamSession], () => {
                return this.utilService.sortByIntDescInternal(this.roomUserListService.userViewInRoom[a.KullaniciId]?.rutbeDerece, this.roomUserListService.userViewInRoom[b.KullaniciId]?.rutbeDerece, () => {
                  return 0;
                });
              });
        });
      });
    });
  }
  setMicUsers() {
    // setTimeout(() => {
    this.isThereOnMic = (this.roomService.currentMicrophoneJsonStruct.M !== null &&
      this.roomService.currentMicrophoneJsonStruct.M !== undefined &&
      this.roomService.currentMicrophoneJsonStruct.M !== "")

    this.clearMicUsers();

    if (this.isThereOnMic) {
      let roomUsers: Array<RoomUser> = new Array<RoomUser>();
      roomUsers.push(this.roomService.roomUserList.find((u => u.KullaniciId.toString() === this.roomService.currentMicrophoneJsonStruct.M)))

      if (this.roomService.currentMicrophoneJsonStruct.M2 !== null &&
        this.roomService.currentMicrophoneJsonStruct.M2 !== undefined &&
        this.roomService.currentMicrophoneJsonStruct.M2.length > 0) {
        this.roomService.currentMicrophoneJsonStruct.M2.forEach(userID => {
          roomUsers.push(this.roomService.roomUserList.find((u => u.KullaniciId.toString() === userID)))
        });
      }

      this.micUsersCount = roomUsers.length;
      roomUsers.forEach(user => {
        this.appendToMicUser(user);
      })
    }
    // }, 1000);
  }
  copyRoomLink() {
    let domain = "https://" + window.location.hostname;
    let sharableLink = "";
      sharableLink = domain + "/join/roomId/" + this.roomService.currentRoom.Info.ID + "/" + this.roomService.currentRoom.Info.ROOM_PASSWORD;
      navigator.clipboard.writeText(sharableLink)
      .then(() => {
        this.messagePanelService.ShowPopupMessageWithLocalization('copy-room-link-success', 2000);
      });
  }
  

  appendToMicUser(user: RoomUser) {
    try {
      let componentFactory = this.componentFactoryResolver.resolveComponentFactory(UserOnMicComponent);
      let viewContainerRef = this.userOnMicHost.viewContainerRef;
      let componentRef = viewContainerRef.createComponent(componentFactory);
      (<UserOnMicComponent>(componentRef.instance)).set(user);
    } catch (error) {
      console.log(error);
      //window.location.reload();
    }
  }

  clearMicUsers() {
    if (this.userOnMicHost && this.userOnMicHost.viewContainerRef)
      this.userOnMicHost.viewContainerRef.clear();
  }
  openPhoneCallingPanel(){
    this.panelManagerService.phoneCallingPopupDisplay = true
  }
  setRoomUser() {
    // this.roomUser = this.roomService.roomUserList.find(f => f.KullaniciId === this.indexedDBService.userFullInfo.KullaniciId);
    this.roomUser = this.roomService.getRoomUserFromActiveListByUserId(this.indexedDBService.userFullInfo.KullaniciId)
  }

  editorHeight($event: number) {
    this.userListHeight = 'calc(100% - ' + (53 + $event) + 'px)';
  }

  setRoomBackground() {
    if (this.roomInfo.Info) {
      if (this.utilService.isNullOrEmtpyString(this.roomInfo.Info?.BACK_IMAGE_ID) || this.roomInfo.Info?.BACK_IMAGE_ID === this.utilService.GuidEmpty()) {
        this.roomBack = this.utilService.hexToRgb(this.roomInfo.Info?.RENK_CHAT_1) + ' linear-gradient(to right, ' + this.utilService.hexToRgb(this.roomInfo.Info?.RENK_CHAT_1) + ', ' + this.utilService.hexToRgb(this.roomInfo.Info?.RENK_CHAT_2) + ')';
      }
      else {
        this.roomBack = `url('${Constants.veri24MessengerRoomBackImageUrl}backimage_${this.roomService.currentRoom.Info?.BACK_IMAGE_ID.toLowerCase()}.${this.roomService.currentRoom.Info.BackImageType}')`;
        // var http = new XMLHttpRequest();
        // var http1 = new XMLHttpRequest();

        // http.open('HEAD', Constants.veri24MessengerRoomBackImageUrl + 'backimage_' + (this.roomInfo.Info?.BACK_IMAGE_ID).toLowerCase() + '.jpg', false);
        // http.send();

        // http1.open('HEAD', Constants.veri24MessengerRoomBackImageUrl + 'backimage_' + (this.roomInfo.Info?.BACK_IMAGE_ID).toLowerCase() + '.gif', false);
        // http1.send();

        // if (http1.status === 200) {
        //   this.roomBack = 'url(' + Constants.veri24MessengerRoomBackImageUrl + 'backimage_' + (this.roomInfo.Info?.BACK_IMAGE_ID).toLowerCase() + '.gif)';
        // }
        // else {
        //   if (http.status === 200) {
        //     this.roomBack = 'url(' + Constants.veri24MessengerRoomBackImageUrl + 'backimage_' + (this.roomInfo.Info?.BACK_IMAGE_ID).toLowerCase() + '.jpg)';
        //   }
        //   else {
        //     this.roomBack = this.utilService.hexToRgb(this.roomInfo.Info?.RENK_CHAT_1) + ' linear-gradient(to right, ' + this.utilService.hexToRgb(this.roomInfo.Info?.RENK_CHAT_1) + ', ' + this.utilService.hexToRgb(this.roomInfo.Info?.RENK_CHAT_2) + ')';
        //   }
        // }
      }
    }
  }

  ngOnDestroy() {
    if (this.roomInfoUpdatedSubscription)
      this.roomInfoUpdatedSubscription.unsubscribe();

    if (this.microphoneStateChangedSubscription)
      this.microphoneStateChangedSubscription.unsubscribe();


    if (this.roomUserListChangedSubscription)
      this.roomUserListChangedSubscription.unsubscribe();

    if (this.friendshipRequestNotificationSubscription)
      this.friendshipRequestNotificationSubscription.unsubscribe();

    if (this.newMessageNotificationTakenSubscription)
      this.newMessageNotificationTakenSubscription.unsubscribe();

    if (this.roomUserListChangedSubscription) {
      this.roomUserListChangedSubscription.unsubscribe();
    }
    if (this.roomMicUserAppendSubscription) {
      this.roomMicUserAppendSubscription.unsubscribe();
    }
  }

  checkFavoriteRoom() {
    if (this.roomService.favoriteRooms?.find((f: FavoriteRoom) => f.OdaId === this.roomService.currentRoom.Info?.ID) !== undefined)
      this.isFavoriteRoom = true;
    else
      this.isFavoriteRoom = false;
  }

  removeFromFavoriteRoom() {
    event.stopPropagation();
    this.confirmationService.confirm({
      message: this.translateService.instant('removeFavoriteRoomWarning'),
      header: this.translateService.instant('Warning'),
      icon: null,
      accept: () => {
        this.roomService.removeFromFavoriteRooms(this.roomService.currentRoom?.Info?.ID).then(() => {
          this.isFavoriteRoom = false;
        }).catch(error => {
        });
        return false;
      },
      reject: () => {
        return false;
      }
    });
  }

  addToFavoriteRoom() {
    event.stopPropagation();
    this.confirmationService.confirm({
      message: this.translateService.instant('addFavoriteRoomWarning'),
      header: this.translateService.instant('Warning'),
      icon: null,
      accept: () => {
        this.roomService.addToFavoriteRooms(this.roomService.currentRoom?.Info?.ID)
          .then(() => {
            this.isFavoriteRoom = true;
          })
          .catch(error => {
          });
        return false;
      },
      reject: () => {
        return false;
      }
    });
  }

  closePanel() {
    this.roomService.closeRoomAIPanel();
    if (this.indexedDBService.userFullInfo.RutbeFullInfo.ADI == "AIUser") {
      return
    }
    this.roomService.leaveFromRoomActions(null).then(() => {
      return this.livekitService.disconnectRoom()
    })
      .catch(error => {
        this.messagePanelService.ShowPopupMessageWithLocalization(error, 2000);
      })
  }

  openRoomContextMenu($event: MouseEvent): void {
    this.showRoomEditorContextMenu = true;
    setTimeout(() => {
      this.contextMenuService.show.next({
        contextMenu: this.roomEditorContextMenuClick.contextMenu,
        event: $event,
        item: this.indexedDBService.userFullInfo.RutbeFullInfo
      });

    }, 100);

    $event.preventDefault();
    $event.stopPropagation();
  }


  openLiveSupportPanel() {
    this.reportService.insertComplainRequest.ComplainType = SikayetTipleri.LiveSupport;
    this.reportService.insertComplainRequest.Html = "";
    this.panelManagerService.isLiveSupport = true;
    this.reportService.reportPopupDisplay = true;
  }








  editorTextChange(text: string) {
    if (!this.utilService.isNullOrEmtpyString(text) && text.length > 0) {
      this.isTyping = true;
    } else {
      this.isTyping = false;
    }
    this.editorTextChanged.emit(text);
  }

  editorEnterPress() {
    this.emojiPanelIsOpen = false;
    this.isTyping = false;
    this.sendMessageOnRoom();
  }
  
  sendFileMessageOnRoom(data:FileMessageData){
    try {
      if (this.lastSendMessageTime > this.utilService.addSecondToDate(this.utilService.newDate(), -1)) {
        this.floodmessageCount++;

        if (this.floodmessageCount > 2) {
          this.sendButtonEnable = false;

          //setTimeout(() => {
          this.sendButtonEnable = true;
          this.floodmessageCount = 0;
          //}, 1000);

          return;
        }
      } else {
        this.floodmessageCount = 0;
      }

      this.lastSendMessageTime = this.utilService.newDate();

      this.roomService.currentMessageType = MessageType.Text;
      this.roomService.sendFileMessageToRoom(this.editor.getEditorTextForSend(),data)
        .then(() => {
          this.editorMessageForSet = Promise.resolve("");
        })
        .catch(error => {
          if (!this.utilService.isNullOrEmtpyString(error))
            this.messagePanelService.ShowPopupMessageWithLocalization(error, 2000);
          //this.messagePanelService.ShowPopupMessageWithLocalization('MessageSendError', 2000);
        })
        .finally(() => {
          this.roomService.setEditorAsDefault();
        });

    }
    catch (error) {
      console.log(error);
    }
  }
  sendMessageOnRoom() {
    try {
      if (this.indexedDBService.userFullInfo.StateId == StateIdList.Cevrimdisi) {
        this.messagePanelService.ShowPopupMessageWithLocalization('Çevrimdışı kullanıcı mesaj gönderemez', 2000);
        return;
      }
      if (this.roomService.currentRoom.Info.SESSIZ && this.indexedDBService.userFullInfo.RutbeDerece <= 0) {
        this.messagePanelService.ShowPopupMessageWithLocalization('Oda Sessiz modda, mesaj atamazsınız.', 2000);
        return;
      }
      if (this.lastSendMessageTime > this.utilService.addSecondToDate(this.utilService.newDate(), -1)) {
        this.floodmessageCount++;

        if (this.floodmessageCount > 2) {
          this.sendButtonEnable = false;

          //setTimeout(() => {
          this.sendButtonEnable = true;
          this.floodmessageCount = 0;
          //}, 1000);

          return;
        }
      } else {
        this.floodmessageCount = 0;
      }

      this.lastSendMessageTime = this.utilService.newDate();

      this.roomService.currentMessageType = MessageType.Text;
      this.roomService.sendMessageToRoom(this.editor.getEditorTextForSend())
        .then(() => {
          this.editorMessageForSet = Promise.resolve("");
        })
        .catch(error => {
          if (!this.utilService.isNullOrEmtpyString(error))
            this.messagePanelService.ShowPopupMessageWithLocalization(error, 2000);
          //this.messagePanelService.ShowPopupMessageWithLocalization('MessageSendError', 2000);
        })
        .finally(() => {
          this.roomService.setEditorAsDefault();
        });

    }
    catch (error) {
      console.log(error);
    }
  }

  onRightClick($event) {
    if (this.indexedDBService.userFullInfo.RutbeFullInfo.GENEL_MESAJ) {
      $event.preventDefault();
      this.isClickRight = true;
    }
  }

  showUserList() {
    this.panelManagerService.showedRoomUserList = !this.panelManagerService.showedRoomUserList;
    if (this.panelManagerService.showedRoomUserList) {
      this.roomService.refreshCurrentUserListPage()
    }
  }

  showChatPanel() {
    this.panelManagerService.showedFriendList = true;
    this.p2pNotification = false;
  }
  
  showIncommingMessagePanel(){
    this.panelManagerService.showedIncommingAnotherUserMessagePanel = true;
    this.panelManagerService.p2pIncomingMessageFromAntoherUserNotification = false 
  }

  toggleAnimationsPanel() {
    if (this.indexedDBService.userFullInfo.StateId == StateIdList.Cevrimdisi) {
      this.messagePanelService.ShowPopupMessageWithLocalization('Çevrimdışı kullanıcı mesaj gönderemez', 2000);
      return;
    }
    this.animationToggle = !this.animationToggle;
  }
  togglSendFolderPanel() {
    if (this.indexedDBService.userFullInfo.RutbeDerece == -1) {
      this.panelManagerService.guestWarningPopupDisplay = true;
      return
    }
    this.panelManagerService.sendFolderToRoomPopupDisplay = !this.panelManagerService.sendFolderToRoomPopupDisplay;
  }
  toggleMicSettingsPanel() {
    this.panelManagerService.showMicSettings = !this.panelManagerService.showMicSettings;
  }
  openRoomRecordPanel() {
    this.panelManagerService.recordListPanelDisplayedSource.next(true)
    this.panelManagerService.roomRecordingPanelDisplay = true
  }
  openYoutubePanel() {
    this.panelManagerService.youtubePlayerPopupDisplay = true;
    this.panelManagerService.youtubePlayerPopupMinus = false;
  }

  showRoomOwnerInfoCard() {
    this.roomService.selectedRoomInfo = {
      RoomId: this.roomService.currentRoom.Info.ID,
      RoomName: this.roomService.currentRoom?.Info.NAME,
      OwnerPicture: this.roomService.currentRoom.OwnerInfo.ProfilResmiId
    }
    this.panelManagerService.roomInfoCardPopupDisplay = true;
  }
}