export const environment = {
  production: true,
  appVersion: '2.49.10-fd3f45c8',
  checkVersion: true,
  builtAppHash: 'fd3f45c88f5d5c7ee50585526914ac48a1d6b429',
  centrifugoWebsocketUrl: 'wss://centri.zumti.com/connection/websocket',
  jitsiurl: 'meetx.turkpages.com',
  webSiteUrl: 'https://web.seslidunya.com/',
  apiUrl: 'https://v2-be.seslidunya.com/',
  natsUrl: 'wss://centri.zumti.com',
  googleCientId: '590432653135-m4etmnl73b4h37pf4peqv3ik951fesm6.apps.googleusercontent.com',
  livekitUrl:   'wss://live-test.zumti.com',
  customerName: 'seslidunyacom',
};