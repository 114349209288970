<div class="room-container" [ngStyle]="{ 'background': roomBack }">
    <!-- 'background-color': '#d18383' -->
    <div class="room-header">
        <div class="action-button" (click)="panelManagerService.openMainPanel(mainPanels.RoomList)">
            <fa-icon [icon]="faArrowLeft"></fa-icon>
        </div>
        <div class="room-info" [ngClass]="{'everyone': roomService.currentRoom?.Info?.KIMLER_GIREBILIR === whoEnterRoomTypes.Everyone, 
        'only-authorities': roomService.currentRoom?.Info?.KIMLER_GIREBILIR === whoEnterRoomTypes.AuthoritiesOnly, 
        'only-users': roomService.currentRoom?.Info?.KIMLER_GIREBILIR === whoEnterRoomTypes.UsersOnly}" pTooltip="{{
            (roomService.currentRoom?.Info?.KIMLER_GIREBILIR === whoEnterRoomTypes.Everyone ? 'roomOpenEverybody' :
            (roomService.currentRoom?.Info?.KIMLER_GIREBILIR === whoEnterRoomTypes.AuthoritiesOnly ? 'roomOpenOnlyAdmins'
            :
            'roomOpenOnlyUsers'))
            | translate
            }}" tooltipPosition="bottom">
            <app-user-photo [userPhotoId]="roomService.currentRoom?.OwnerInfo?.ProfilResmiId" [width]="50" [height]="50"
                [showStateIcon]="false" class="room-owner-photo" (click)="showRoomOwnerInfoCard()"></app-user-photo>

            <!-- <div class="room-name">{{ roomService.currentRoom?.Info?.NAME }}</div> -->
            <!-- style="animation: blinker 2s linear infinite;animation-delay: 2000ms;" -->
            <marquee width="100%" direction="left" class="rotater" scrollamount="2">
                {{ roomService.currentRoom?.Info?.NAME }}
            </marquee>
            <div class="room-owner">
                <small>
                    {{
                      roomService.currentRoom?.OwnerInfo?.KullaniciAdi
                        ? roomService.currentRoom?.OwnerInfo?.KullaniciAdi
                        : (roomService.environmentVariable.UygulamaAdi ? roomService.environmentVariable.UygulamaAdi : constants.APPLICATION_NAME)
                    }}
                  </small>
                  
                <fa-icon  *ngIf="indexedDBService.userFullInfo.RutbeDerece != -1" [icon]="faHeart" class="cursor" [ngStyle]="{'color': isFavoriteRoom ? '#ff4040' : 'white', 'font-size': '20px',
                'padding': '0 8px'}" (click)="isFavoriteRoom ? removeFromFavoriteRoom() : addToFavoriteRoom()"
                    pTooltip="{{ isFavoriteRoom ? ('removeFromFavoriteRoom' | translate) : ('addToFavoriteRoom' | translate) }}"
                    tooltipPosition="top"></fa-icon>
            </div>
        </div>





        <fa-icon  *ngIf="indexedDBService.userFullInfo.RutbeDerece != -1" [icon]="faEllipsisV" class="header-menu-icon" pTooltip="Menü" tooltipPosition="bottom"
            (click)="openRoomContextMenu($event)">
        </fa-icon>

        <fa-icon [icon]="faRedoAlt" class="header-menu-icon" (click)="refreshPage()"></fa-icon>

        <fa-icon [icon]="faTimes" class="header-menu-icon" (click)="closePanel()"></fa-icon>
        
        <div class="trophy-container" pTooltip="{{ 'room-point' | translate }}" tooltipPosition="bottom">
            <fa-icon class="trophy ml-2" [icon]="faTrophy"></fa-icon>
            <span class="room-trophy-count">{{  (roomService.currentRoom.Info?.Point + roomService.currentRoom.Info?.Stats) | kFormatter }}</span>
        </div>
        <div class="room-user-count" (click)="showUserList()">
            <fa-icon [icon]="faUserFriends"></fa-icon>
            <span class="ml-2">{{ roomService.roomUserCount }}</span>
        </div>
    </div>



    <div class="room-mic-users"
        [ngStyle]="{'grid-template-columns': 'repeat(' + (micUsersCount > 4 ? 4 : micUsersCount) + ', minmax(0, 1fr))'}">
        <ng-template app-user-on-mic-host></ng-template>
        <!--TODO micteki userları host template olarak koy..-->
    </div>

    <div class="room-chat-bubble">
        <app-room-chat-bubble></app-room-chat-bubble>
    </div>

    <div class="room-chat-editor">

        <div class="editor-panels">
            <app-room-chat-buttons-animations [isDisplay]="animationToggle"></app-room-chat-buttons-animations>
            <app-room-chat-microphone-settings></app-room-chat-microphone-settings>
        </div>

        <div class="chat-actions">
            <div class="action-button" *ngIf="indexedDBService.userFullInfo.RutbeDerece == 10 && roomService.currentRoom.Info.SIP_IS_ON && roomService.environmentVariable.IsSipOn" (click)="openPhoneCallingPanel()" [pTooltip]="'Aramaya dahil et'|translate" tooltipPosition="top">
                <fa-icon [icon]="faPhone"></fa-icon>
            </div>
            <div *ngIf="roomService.environmentVariable.CanRecord && indexedDBService.userFullInfo.RutbeDerece != -1" class="action-button" pTooltip="Kayıtlar" tooltipPosition="left" (click)="openRoomRecordPanel()">
                <img src="../../../../../assets/icons/records.png" class="records-icon" alt="">
            </div>
        
            <div class="action-button" pTooltip="Gelen Mesajlar" tooltipPosition="left" (click)="showIncommingMessagePanel()">
                <fa-icon [icon]="faCommentAlt" style="color: salmon;"></fa-icon>
                <span class="p2p-notification" *ngIf="panelManagerService.p2pIncomingMessageFromAntoherUserNotification">1</span>
            </div>
        
            <div class="action-button" pTooltip="Sohbetler" tooltipPosition="left" (click)="showChatPanel()">
                <fa-icon [icon]="faComment" style="color: salmon;"></fa-icon>
                <span class="p2p-notification" *ngIf="p2pNotification || indexedDBService.Friends?.IncomingFriends?.length">1</span>
            </div>
            
            <div *ngIf="indexedDBService.userFullInfo.RutbeDerece != -1" class="action-button" pTooltip="Canlı Destek - Başvuru" tooltipPosition="left" (click)="openLiveSupportPanel()">
                <fa-icon [icon]="faHeadset" style="color: palegreen;"></fa-icon>
            </div>
            <div class="action-button" (click)="toggleAnimationsPanel()" #animation pTooltip="{{ 'sendanimation' | translate }}" tooltipPosition="top">
                <fa-icon [icon]="faFilm" style="color: turquoise;"></fa-icon>
            </div>
            <div class="action-button" (click)="copyRoomLink()" [pTooltip]="'copy-room-link'|translate" tooltipPosition="top">
                <fa-icon [icon]="faShare"></fa-icon>
            </div>
            <div *ngIf="roomService.currentRoom.Info.CAN_SHARE_FILE" class="action-button" (click)="togglSendFolderPanel()" pTooltip="Dosya gönder" tooltipPosition="top">
                <fa-icon [icon]="faFolder" class="editor-icon"></fa-icon>
            </div>
            <div class="action-button" #micIcon (click)="toggleMicSettingsPanel()" pTooltip="{{ 'Mikrofon Ayarları (mute)' | translate }}" tooltipPosition="top">
                <fa-icon [icon]="roomService.allParticipantAreMuted ? faVolumeOff : faVolumeUp" style="color: yellow;"></fa-icon>
            </div>
            <div class="message-send-menu" #messageSendingTypeMenu *ngIf="isClickRight">
                <fa-icon [icon]="faPaperPlane" class="editor-icon" pTooltip="{{ 'Normal' | translate }}" tooltipPosition="left" (click)="roomService.selectedSendingType(messageSendingType.Normal)"></fa-icon>
                <fa-icon [icon]="faUser" class="editor-icon" pTooltip="{{ 'GenelHerkes' | translate }}" tooltipPosition="left" (click)="roomService.selectedSendingType(messageSendingType.GenelHerkes)"></fa-icon>
                <fa-icon [icon]="faUserTie" class="editor-icon" pTooltip="{{ 'GenelAdmin' | translate }}" tooltipPosition="left" (click)="roomService.selectedSendingType(messageSendingType.GenelAdmin)"></fa-icon>
            </div>
        </div>

        <div class="action-button editor-button" (click)="roomService.clickMicrophoneButton(false)"
            [ngClass]="{'button-disabled' : roomService.micAccessButtonDisabled, 'button-enabled': !roomService.micAccessButtonDisabled}"
            pTooltip="{{  microphoneState === _microphoneState.Stopped ? ('takemicrophone' | translate) : ('stopmicrophone' | translate) }}"
            tooltipPosition="top"
            [ngClass]="{'button-disabled' : roomService.micAccessButtonDisabled, 'button-enabled': !roomService.micAccessButtonDisabled}">
            <fa-icon [icon]="microphoneState === _microphoneState.Stopped ? faMicrophone : faMicrophoneSlash"
                [ngStyle]="{'color': microphoneState === _microphoneState.Stopped ? 'white' : 'red'}"></fa-icon>
        </div>
        <div class="action-button editor-button" (click)="roomService.clickMicrophoneButton(true)"
            pTooltip="{{ 'handupdown' | translate }}" tooltipPosition="top"
            [ngClass]="{'button-disabled' : roomService.micAccessButtonDisabled, 'button-enabled': !roomService.micAccessButtonDisabled}">
            <fa-icon [icon]="faHandPaper"></fa-icon>
        </div>
        <div class="action-button editor-button" (click)="roomService.openCameraNew(false)">
            <fa-icon [icon]="faCamera"></fa-icon>
        </div>

        <div class="editor">
            <p-scrollPanel [style]="{ 'width': '100%' }" class="editor-content-scroll">
                <app-editor (editorTextChanged)="editorTextChange($event)" (editorEnterPressed)="editorEnterPress()"
                    #editor [editorMessageForSet]="editorMessageForSet" [editorOn]="editorOn.Room"></app-editor>
            </p-scrollPanel>

            <fa-icon
                [icon]="(roomService.currentMessageSendingType === messageSendingType.Normal ? faPaperPlane : (roomService.currentMessageSendingType === messageSendingType.GenelHerkes) ? faUser : faUserTie)"
                [style.opacity]="sendButtonEnable===true?1:0.3" class="editor-icon" (click)="sendMessageOnRoom()"
                (contextmenu)="onRightClick($event)"></fa-icon>
        </div>
    </div>
</div>

<app-room-editor-context-menu *ngIf="showRoomEditorContextMenu" [roomUser]="roomUser"></app-room-editor-context-menu>